/* eslint-disable prettier/prettier */
import { html, LitElement } from 'lit';
import { customElement, query, state, property } from 'lit/decorators.js';
import { connect } from 'pwa-helpers';
import { ObjectJson } from '../../redux/actions';
import * as XLSX from 'xlsx';
import readXlsxFile from 'read-excel-file'
import Ajv from 'ajv';
import '@vaadin/vaadin-list-box';
import '@vaadin/vaadin-text-field';
import { errorNotification } from '../../services/notifications';
import { AxiosResponse } from 'axios';
import {
  OrganizationName,
  RoleList,
  User,
  FolioApi,
  AppApi,
  TemplateApi,
  ConfigurationApi,
  SavedConfigurationApi,
  RequestBatchFolioCreate,
  SavedConfigurationApiGetSavedConfigurationRequest,
  RequestFolioBatch,
  RequestBusinessBatch,
  RequestAffiliationBatch,
  RequestUpdateFolioBatch,
  AffiliationApi,
  BusinessApi
} from '../../../api';
import '@vaadin/vaadin-text-field';
import { apisConfigurator } from '../../../src/utils/axios';
import {
  typeDataObject,
  addDefault,
  generate_table,
  eliminarCaracteresMayusculas,
} from '../../../src/utils/functions';
import store from '../../../src/redux/store';
import { State } from '../../../src/redux/types';
import { PARAMETROS } from '../../../src/utils/constant';
import router from '../../router/router';
import { agregarPlantilla, ConfigConsole } from '../../../src/redux/actions';
import { JSONEditor } from '@json-editor/json-editor';
import { MostrarFalladosModal } from '../../components/modals/MostrarFallados';
import '../../components/modals/MostrarFallados';
import { MostrarRegistradosModal } from '../../components/modals/MostrarRegistrados';
import '../../components/modals/MostrarRegistrados';
@customElement('editar-pre-configuracion-banorte-modals')
export class EditarPreConfiguracionBanorteModals extends connect(store)(LitElement) {
  private templateApi: TemplateApi;
  private affiliationApi: AffiliationApi;
  private businessApi: BusinessApi;
  private folioApi: FolioApi;
  private appApi: AppApi;
  private configurationApi: ConfigurationApi;
  private savedConfigurationApi: SavedConfigurationApi;

  createRenderRoot() {
    return this; // turn off shadow dom to access external styles
  }

  private constructor() {
    super();
    this.templateApi = new TemplateApi(...apisConfigurator(''));
    this.appApi = new AppApi(...apisConfigurator(''));
    this.businessApi = new BusinessApi(...apisConfigurator(''));
    this.affiliationApi = new AffiliationApi(...apisConfigurator(''));
    this.folioApi = new FolioApi(...apisConfigurator(''));
    this.savedConfigurationApi = new SavedConfigurationApi(...apisConfigurator(''));
    this.configurationApi = new ConfigurationApi(...apisConfigurator(''));
  }

  @state()
  plantillas: any = {};

  @query('#name')
  nameInput!: HTMLInputElement;

  @query('#lastName')
  lastNameInput!: HTMLInputElement;

  @state()
  private obj?: any;

  @state()
  DataJson: any = [];

  @state()
  loading = true;

  @state()
  dataFolio?: any;

  @state()
  dataAfiliacion?: any;

  @state()
  dataRazonSocial?: any;

  @state()
  editUser?: User;

  @state()
  private controlPlantilla: Object[] = [
    {
      name: ' ',
    },
  ];

  @state()
  token?: number;

  @state()
  habilitar?: boolean= true;

  @state()
  count?:any = 0;

  @state()
  roles: RoleList[] = [];

  @state()
  nombrePlantilla?: string = 'Nombre de la plantilla';

  @state()
  descripcionPlantilla?: string = '';

  @state()
  rolesUsuario: string[] = [];

  @state()
  organizationUsuario: string = '';

  @state()
  private templatesVerificar: string[] =[]

  @state()
  private templates:any = true;

  @state()
  aux: string = '';

  @state()
  organizaciones: OrganizationName[] = [];

  @state()
  rol?: string = '';

  @state()
  checks: boolean[] = [];

  @state()
  bandCarga: number = 0;

  @state()
  bandTable: number = 0;

  @state()
  countSuccessFolio:any = [];

  @state()
  countSuccessAffiliation: any = [];

  @state()
  countSuccessRazonSocial: any = [];

  @state()
  band: number = 0;

  @state() 
  private preConfig: any[] = [
    {idTemplate:"c829e7b7-b956-4695-8222-c6221cf248c0",name:"Banorte - Terminal - F",id:"d3ce2cac-8c9e-4e10-ae82-fecf4e7ae538"},
    {idTemplate:"b3618d37-b3e6-40e2-a02f-f2d19582b425",name:"Banorte - Comercio - F",id:"cfafb11a-6800-4b25-8dcd-8c4a9303a891"},
    {idTemplate:"c99b1812-4b67-40eb-8075-6ce0b4aea653",name:"Banorte - Autorizador - F",id:"c4a66552-1c09-4116-ad7c-55ee8861ed2d"}
  ]

  @state()
  JsonSchema?: any;

  @state()
  countAfiliacionn:any = [];

  @state()
  errorAfiliacionn:any = [];

  @state()
  erroresFolios:any = [];

  @state()
  foliosValidos:any = [];

  @state()
  countRazonSociall:any = [];

  @state()
  countMultimerchan:any = [];

  @state()
  countFolio:any = [];

  @state()
  errorFolio:any = [];

  @state()
  errorRazonSociall:any = [];

  @state()
  savedConfig?: any = [];

  @state()
  parameters?: Object;

  @state()
  existConfig?: boolean;

  @state()
  validError?: any;

  @state()
  loader = false;

  @state()
  appId:any = "";

  @state()
  autorizadorF:any[] = [];

  @state()
  comercioF:any = [];

  @state()
  terminalF:any = [];

  @state()
  final_vals: RequestBatchFolioCreate[] = [];

  @state()
  editor?: any;

  @state()
  prueba?: object;

  @state()
  id_plantilla?: string;

  @state()
  organization!: Array<string>;

  private get loaderContainer() {
    return document.getElementById('loader-container') as any;
  }

  private get formData() {
    return document.getElementById('editorHolder') as any;
  }

  private get editorContainer() {
    return document.querySelector(`.je-object__container`) as HTMLInputElement;
  }

  private get collapse() {
    return document.querySelector(`.je-object__title`) as HTMLInputElement;
  }

  get mostrarFalladosModal() {
    return document.getElementById('mostrarFalladosModal') as MostrarFalladosModal;
  }

  get mostrarRegistradosModal() {
    return document.getElementById('mostrarRegistradosModal') as MostrarRegistradosModal;
  }

  private get collapse2() {
    return document.querySelector(
      `.json-editor-btn-edit_properties`
    ) as HTMLInputElement;
  }

  private get collapse3() {
    return document.querySelector(`.json-editor-btn-edit`) as HTMLInputElement;
  }

  private get jeindented() {
    return document.querySelector(`.je-indented-panel`) as HTMLInputElement;
  }

  get jeFormInput() {
    return document.querySelector('.je-form-input-label') as HTMLInputElement;
  }

  idGet(atributo: string) {
    return document.getElementById(atributo) as HTMLInputElement;
  }

  validGet(atributo: string) {
    return document.querySelector(`.${atributo}`) as HTMLInputElement;
  }

  async open(id_plantilla: string) {
    this.id_plantilla = id_plantilla;
    this.dialog.open = true;
    localStorage.removeItem('batchFolio');
    localStorage.removeItem('batchAfiliacion');
    localStorage.removeItem('batchRazonSocial');
    localStorage.removeItem('errorMultimerchan');
    localStorage.removeItem('countMultimerchan');
    localStorage.removeItem('errorAfiliacion');
    localStorage.removeItem('countAfiliacion');
    localStorage.removeItem('errorRazonSocial');
    localStorage.removeItem('countRazonSocial');
    localStorage.removeItem('errorFolio');
    localStorage.removeItem('countFolio');
  }

  close() {
    this.bandCarga = 0;
    this.bandTable = 0;
    this.dialog.open = false;
  }

  private get dialog() {
    return document.getElementById('dialog-pre-configuracion-edicion')! as any;
  }

  esconderDiv() {
    const collapse = this.collapse;
    const collapse2 = this.collapse2;
    const collapse3 = this.collapse3;
    collapse.style.display = 'none';
    collapse2.style.display = 'none';
    collapse3.style.display = 'none';
    const jeindented = this.jeindented;
    jeindented.setAttribute('style', 'border:none');
    const div: any = jeindented.querySelector('div');
    const div2 = div.querySelector('div');
    div2.setAttribute('class', 'flex flex-wrap my-4 mx-4');
  }

  async getTemplate2(id: string) {
    var resTer: AxiosResponse;
    try {
      resTer = await this.templateApi.getTemplate({ id });
      this.plantillas = 
        {
          ...this.plantillas,
          ...resTer.data.schema.properties
        }
      ;
      console.log("PARAMETROS",this.plantillas);
      localStorage.removeItem('plantillas');
      localStorage.setItem('plantillas', JSON.stringify(this.plantillas));
    } catch (error) {
    } finally {
    }
  }

  async getApp(appId: any) {

    try {
      const res = await this.appApi.getApp({ id: appId });
      const app = res.data;
      console.log("getApp",res.data)
      let templates:any = res.data.templates;
      templates.map(async(item:any,index:number)=>{
        await this.getTemplate2(item);
      })
      //this.app = app;

    } catch (err) {
      errorNotification('Error al obtener la app');
      console.log(err);
    } finally {    

    }
  }
 
  async firstUpdated() {
    this.appId = localStorage.getItem('appId') ?? "";
    await this.getApp(this.appId)
  }

  async enviarCarga() {
    let batchFolio:string = localStorage.getItem('batchFolio') ?? "";
    let dataFolio:any[] = JSON.parse(batchFolio);
    let batchAfiliacion:string =  localStorage.getItem('batchAfiliacion') ?? "";
    let batchRazonSocial:string = localStorage.getItem('batchRazonSocial') ?? "";
    let dataAfiliacion:any[] = [];
    let dataRazonSocial:any[] = [];

    if(localStorage.getItem('batchAfiliacion')){
      dataAfiliacion = JSON.parse(batchAfiliacion);
    }

    if(localStorage.getItem('batchRazonSocial')){
      dataRazonSocial = JSON.parse(batchRazonSocial);
    }
    let indexx:any;
    dataFolio.map((item:any,index:number)=>{
      item.configurations[0].parametersArray.map((param:any,index:number)=>{
        indexx=param.index;
        let objeto2 = {...this.comercioF,...param}
        item.configurations[0].parametersArray[index] = objeto2;
      })
    })

    this.autorizadorF.map((item:any,index:number)=>{
      item.index = 0;
    })

    dataFolio.map((item:any,index:number)=>{
      item.configurations.push({
        templateId:"c99b1812-4b67-40eb-8075-6ce0b4aea653",
        parametersArray:this.autorizadorF
      })
    })
    console.log(this.comercioF)

    dataFolio.map((item:any,index:number)=>{
      let objeto2 = item.configurations[1].parameters;
      objeto2 = {...this.terminalF,...objeto2}
      item.configurations[1].parameters = objeto2;
      objeto2 = {}
    })

    let uniqueObjects:any = [];

    for (const obj of dataAfiliacion) {
      const existingObject = uniqueObjects.find(
        (uniqueObj:any) => uniqueObj.affiliationId === obj.affiliationId && uniqueObj.businessId === obj.businessId
      );

      if (!existingObject) {
        uniqueObjects.push(obj);
      }
    }
    dataAfiliacion = uniqueObjects;

    uniqueObjects = [];

    for (const obj of dataRazonSocial) {
      const existingObject = uniqueObjects.find(
        (uniqueObj:any) => uniqueObj.businessId === obj.businessId
      );
 
      if (!existingObject) {
        uniqueObjects.push(obj);
      }
    }

    dataRazonSocial = uniqueObjects;


    console.log(dataFolio)
    console.log(dataAfiliacion)
    console.log(dataRazonSocial)
    this.dataFolio = dataFolio;
    this.dataAfiliacion = dataAfiliacion;
    this.dataRazonSocial = dataRazonSocial;

    const promises = [
      this.savedFolioBatch(dataFolio),
      this.savedRazonSocialBatch(dataRazonSocial),
      this.savedAffiliationBatch(dataAfiliacion)

    ].map(p => {
      console.log("PROMISE ALL",p)
      p.catch(e => e)
    }); 
    await Promise.all(promises);
    this.bandCarga = 2;

  } 
  async savedFolioBatch(configuration: any){
    const requestUpdateFolioBatch : RequestUpdateFolioBatch = {
      appId:this.appId,
      batch: configuration,
    };
    console.log(requestUpdateFolioBatch)
    try {
      const data = await this.folioApi.folioUpdateBatch({requestUpdateFolioBatch})
      console.log(data)
      if (data.status == 200) {
        this.countSuccessFolio = data.data.list;
      }else{
        errorNotification("error al registrar la carga");
      }
    } catch (error) {
      console.log(`${error}`)
    }
  }

  async savedAffiliationBatch(configuration: any) {
    const requestAffiliationBatch : RequestAffiliationBatch = {
      appId:this.appId,
      batch: configuration,
    };
    console.log(requestAffiliationBatch)

    try {
      const res = await this.affiliationApi.affiliationBatch({
        requestAffiliationBatch
      });
      console.log(res)
      if (res.status == 200) {
        this.countSuccessAffiliation = res.data.list;
      }else{
        errorNotification("error al registrar la carga");
      }
    } catch (err) {}
  }

  async savedRazonSocialBatch(configuration:any) {
    const requestBusinessBatch : RequestBusinessBatch = {
      appId:this.appId,
      batch: configuration,
    };
    console.log(requestBusinessBatch)

    try {
      const res = await this.businessApi.businessBatch({
        requestBusinessBatch
      });
      console.log(res)
      if (res.status == 200) {
        this.countSuccessRazonSocial = res.data.list;
      }else{
        errorNotification("error al registrar la carga");
      } 
    } catch (err:any) {
      errorNotification(err);
    } 
  }

  async getSavedConfiguration(id: string) {
    console.log(id)
    const data:SavedConfigurationApiGetSavedConfigurationRequest = {
      id
    }
    try {
      const res = await this.savedConfigurationApi.getSavedConfiguration(data)
      const saved:any = res.data
      console.log(saved)
      //this.savedConfig.push(saved)
      //console.log(this.savedConfig)
      let name = saved.name;
      if(name === "Banorte - Autorizador - F"){
        this.autorizadorF = saved.parametersArray;
        this.count++
      }else if(name === "Banorte - Comercio - F"){
        this.comercioF = saved.parametersArray[0];
        this.count++
      }else if(name === "Banorte - Terminal - F"){
        this.terminalF = saved.parameters
        this.count++
      }
    if(this.count>2){
      this.habilitar=false;
    }
      console.log(this.comercioF)
      console.log(this.terminalF)
      console.log(this.count)
    } catch (error) {
      console.log(error)
    }
  }
  async getTemplate(id: string) {
    var resTer: AxiosResponse;
    try {
      resTer = await this.templateApi.getTemplate({ id });
      this.plantillas = 
        {
          ...this.plantillas,
          ...resTer.data.schema.properties
        }
      ;
      console.log("PARAMETROS",this.plantillas);
    } catch (error) {
    }
  }

  async setConfiguration() {
    const editorContainer = this.editorContainer;
    if (editorContainer) {
      await editorContainer.remove();
    }
    const element = this.formData;
    let properties = this.JsonSchema.properties;
    this.editor = new JSONEditor(element, {
      ajax: true,
      schema: {
        type: 'object',
        title: 'Car',
        properties: properties,
      },
    });
  }

  private get displayArea() {
    return document.getElementById('displayArea') as any;
  }

  private get disabledButton() {
    return document.getElementById('crearTerminal') as any;
  }

  private get alertCampo() {
    return document.getElementById('alertCampo') as any;
  }

  private get seleccionarArchivo() {
    return document.getElementById('seleccionar-archivo-banorte') as any;
  }


  private get botonCsv() {
    return document.querySelector('#seleccionar-archivo-banorte')! as HTMLInputElement;
    //this.bandBatch= true;
  }

  async verificarAfiliation2(titulos:any,parametros:any,validacion:any,indexx:number){
    let countAfiliacion:any[] = []
    let band:boolean = true;
    const matriz:any = {}
    let errorAfiliacion:any[] = []
    let valores = ["NOMBREDELACADENA","NOMBREDELATIENDA","MERCHANTID","CONTACTO","EMAIL","TELEFONO","RFC","CP"];
    valores.map((item:any,index:number) =>{
      const indice = titulos.findIndex((elemento:any) => elemento === item);
      const parametro:any = parametros[indice]
      const objeto = validacion[item];
      const regex = new RegExp(objeto.exReg);
      if(regex.test(parametro) ){
        matriz[objeto.name]=parametro;
      }else{
        band = false;
      }
    })

    if(band) {
      this.countAfiliacionn.push(matriz)
    }else{
      this.errorAfiliacionn.push(indexx)
    }

  }

  async verificarAfiliation(texto: String, regexp:any, item:any){
    switch (texto) {
      case "NOMBREDELACADENA":
        if (regexp.test(item)) {
          return true;
        } else {
          return false
        }
        break;
      case "NOMBREDELATIENDA":
        if (regexp.test(item)) {
          return true;
        } else {
          return false
        }
        break;
      case "MERCHANTID":
        if (regexp.test(item)) {
          return true;
        } else {
          return false
        }
        break;
      case "CONTACTO":
        if (regexp.test(item)) {
          return true;
        } else {
          return false
        }
        break;
      case "EMAIL":
        if (regexp.test(item)) {
          return true;
        } else {
          return false
        }
        break;
      case "TELEFONO":
        if (regexp.test(item)) {
          return true;
        } else {
          return false
        }
        break;
      case "RFC":
        if (regexp.test(item)) {
          return true;
        } else {
          return false
        }
        break;
      case "CP":
        if (regexp.test(item)) {
          return true;
        } else {
          return false
        }
        break;
      default:
        return false;
    }
  
  }

  async verificarRazonSocial(texto: String, regexp:any, item:any){
    switch (texto) {
      case "NOMBREDELACADENA":
        if (regexp.test(item)) {
          return true;
        } else {
          return false
        }
      case "NOMBREDELATIENDA":
        if (regexp.test(item)) {
          return true;
        } else {
          return false
        }
      case "MERCHANTID":
        if (regexp.test(item)) {
          return true;
        } else {
          return false
        }
      case "CONTACTO":
        if (regexp.test(item)) {
          return true;
        } else {
          return false
        }
      case "EMAIL":
        if (regexp.test(item)) {
          return true;
        } else {
          return false
        }
      case "TELEFONO":
        if (regexp.test(item)) {
          return true;
        } else {
          return false
        }
      case "RFC":
        if (regexp.test(item)) {
          return true;
        } else {
          return false
        }
      case "CP":
        if (regexp.test(item)) {
          return true;
        } else {
          return false
        }
      case "DESCRIPTION":
        if (regexp.test(item)) {
          return true;
        } else {
          return false
        }
      default:
        return false;
    }
  
  }

  remove(){
    localStorage.removeItem('batchFolio');
    localStorage.removeItem('batchAfiliacion');
    localStorage.removeItem('batchRazonSocial');
    localStorage.removeItem('errorMultimerchan');
    localStorage.removeItem('countMultimerchan');
    localStorage.removeItem('errorAfiliacion');
    localStorage.removeItem('countAfiliacion');
    localStorage.removeItem('errorRazonSocial');
    localStorage.removeItem('countRazonSocial');
    localStorage.removeItem('errorFolio');
    localStorage.removeItem('countFolio');
  }

  async recorrerMatriz(values:any){
    localStorage.removeItem('errorMultimerchan');
    localStorage.setItem('errorMultimerchan', JSON.stringify([]));
    localStorage.removeItem('countMultimerchan');
    localStorage.setItem('countMultimerchan', JSON.stringify([]));
    localStorage.removeItem('errorAfiliacion');
    localStorage.setItem('errorAfiliacion', JSON.stringify([]));
    localStorage.removeItem('countAfiliacion');
    localStorage.setItem('countAfiliacion', JSON.stringify([]));
    localStorage.removeItem('errorRazonSocial');
    localStorage.setItem('errorRazonSocial', JSON.stringify([]));
    localStorage.removeItem('countRazonSocial');
    localStorage.setItem('countRazonSocial', JSON.stringify([]));
    localStorage.removeItem('errorFolio');
    localStorage.setItem('errorFolio', JSON.stringify([]));
    localStorage.removeItem('countFolio');
    localStorage.setItem('countFolio', JSON.stringify([]));
    let countAfiliacion:any[] = [];
    let countRazonSocial:any[] = [];
    let errorRazonSocial:any[] = [];
    let afiliationId = ""

    //let verificarAfiliacion2 = this.verificarAfiliation2;
    //let verificarRazonSocial:any = this.verificarRazonSocial;

    console.log("adentro")
    let titulosCSV:any = [];
    console.log("values",values)
    values = values.filter((elemento:any) => elemento !== "");
    await values.map(async(val:any, indexx:number) => {
      if (indexx == 0) {
        console.log("val",val)
        val.map((item:any,index:number) =>{
          titulosCSV[index] = eliminarCaracteresMayusculas(item);
        })
      }else{
        const vall = val;
        const constantes:any = PARAMETROS;
        //AFILIACION
        console.log("AFILIACION")
        let countAfiliacion:any[] = []
        let band:boolean = true;
        const matriz:any = {}
        let dataError:any[] = []
        let errorAfiliacion:any[] = []
        let valores = ["NOMBREDELACADENA","NOMBREDELATIENDA","MERCHANTID","CONTACTOA","TELEFONOA","RFCA","CPA"];
        valores.map((item:any,index:number) =>{
          const constante = constantes[item];
          const parametro = vall[constante.numero-2];
          const regex = new RegExp(constante.exReg);
          console.log(`${item}`,parametro)
          console.log(parametro !== undefined && parametro !== null)
          if(parametro !== undefined && parametro !== null){ 
            if(regex.test(parametro)){
              let valor:any = "";
              let dataPlantillas:string = localStorage.getItem('plantillas') ?? "";
              let plantillas:any = JSON.parse(dataPlantillas);
              if(plantillas[constante.name] !== undefined){
                if (plantillas[constante.name].type==="number") {
                  // Es un número
                  valor = Number(parametro);
                } else if (plantillas[constante.name].type==="boolean") {
                  // Es un booleano
                  if(parametro === "TRUE") {
                    valor = true;
                  }else{
                    valor = false;
                  }
                  console.log(valor)
                } else {
                  // Es un string
                  valor = `${parametro}`;
                }
                console.log(valor)
                if(valor===null){
                  //matriz[constante.name]="";
                  band = false;
                  dataError.push({
                    index:indexx,
                    search:false,
                    parametro:item,
                  })
                }else{
                  matriz[constante.name]=valor;
                }
              }else{
                matriz[constante.name]=`${parametro}`;
              }
            }else{
              band = false;
              dataError.push({
                index:indexx,
                search:false,
                parametro:item,
              })
            }
          }else{
            band = false;
            dataError.push({
              index:indexx,
              search:true,
              parametro:item,
            })
          }
        })
        console.log(matriz)
        matriz["savedConfigurations"] = [];
        if(band) {

          let batch:string = localStorage.getItem('batchAfiliacion') ?? "";
          let dataBatch:any[] = JSON.parse(batch);
          dataBatch.push(matriz)
          localStorage.setItem('batchAfiliacion', JSON.stringify(dataBatch));

          let errorAfiliacion:string = localStorage.getItem('countAfiliacion') ?? "";
          let dataAfiliacion:any[] = JSON.parse(errorAfiliacion);
          dataAfiliacion.push(indexx)
          localStorage.setItem('countAfiliacion', JSON.stringify(dataAfiliacion));
        }else{
          localStorage.removeItem('batchAfiliacion');
          localStorage.setItem('batchAfiliacion', JSON.stringify([]));
          let errorAfiliacion:string = localStorage.getItem('errorAfiliacion') ?? "";
          let dataAfiliacionError:any[] = JSON.parse(errorAfiliacion);
          dataAfiliacionError.push(dataError)
          localStorage.setItem('errorAfiliacion', JSON.stringify(dataAfiliacionError));
        }

        // Razon social
        console.log("RAZON SOCIAL")
        let countRazonSocial:any[] = []
        let band2:boolean = true;
        const matriz2:any = {}
        let dataError2:any[] = []
        let errorRazonSocial:any[] = []
        let valores2 = ["NOMBREDELACADENA","DESCRIPTION","CONTACTORS","EMAIL","TELEFONORS","RFCRS","CPRS"];
        valores2.map((item:any,index:number) =>{
          const constante = constantes[item];
          const parametro = vall[constante.numero-2];
          const regex = new RegExp(constante.exReg);
          console.log(parametro)
          if(parametro !== undefined && parametro !== null){ 
            if(regex.test(parametro)){
              let valor:any = "";
              let dataPlantillas:string = localStorage.getItem('plantillas') ?? "";
              let plantillas:any = JSON.parse(dataPlantillas);
              if(plantillas[constante.name] !== undefined){
                if (plantillas[constante.name].type==="number") {
                  // Es un número
                  valor = Number(parametro);
                } else if (plantillas[constante.name].type==="boolean") {
                  // Es un booleano
                  if(parametro === "TRUE") {
                    valor = true;
                  }else{
                    valor = false;
                  }
                  console.log(valor)
                } else {
                  // Es un string
                  valor = parametro;
                }
                console.log(valor)
                if(valor===null){
                  //matriz2[constante.name]="";
                  band2 = false;
                  dataError.push({
                    index:indexx,
                    search:false,
                    parametro:item,
                  })
                }else{
                  matriz2[constante.name]=valor;
                }
              }else{
                if (constante.type==="number") {
                  // Es un número
                  valor = Number(parametro);
                } else if (constante.type==="boolean") {
                  // Es un booleano
                  if(parametro === "TRUE") {
                    valor = true;
                  }else{
                    valor = false;
                  }
                  console.log(valor)
                } else if(constante.type==="string") {
                  // Es un string
                  valor = `${parametro}`;
                }else{
                  valor = parametro;
                }
                console.log(valor)
                if(valor===null){
                  //matriz2[constante.name]="";
                  band2 = false;
                  dataError.push({
                    index:indexx,
                    search:false,
                    parametro:item,
                  })
                }else{
                  matriz2[constante.name]=valor;
                }
              }
            }else{
              band2 = false;
              dataError2.push({
                index:indexx,
                search:false,
                parametro:item,
              })
            }
          }else{
            band2 = false;
            dataError2.push({
              index:indexx,
              search:true,
              parametro:item,
            })
          }
        }) 
        console.log(matriz2)
        matriz2["savedConfigurations"] = [];
        if(band2) {

          let batch:string = localStorage.getItem('batchRazonSocial') ?? "";
          let dataBatch:any[] = JSON.parse(batch);
          dataBatch.push(matriz2)
          localStorage.setItem('batchRazonSocial', JSON.stringify(dataBatch));

          let errorAfiliacion:string = localStorage.getItem('countRazonSocial') ?? "";
          let dataAfiliacion:any[] = JSON.parse(errorAfiliacion);
          dataAfiliacion.push(indexx)
          localStorage.setItem('countRazonSocial', JSON.stringify(dataAfiliacion));
        }else{
          localStorage.removeItem('batchRazonSocial');
          localStorage.setItem('batchRazonSocial', JSON.stringify([]));
          let errorAfiliacion:string = localStorage.getItem('errorRazonSocial') ?? "";
          let dataAfiliacionError:any[] = JSON.parse(errorAfiliacion);
          dataAfiliacionError.push(dataError2)
          localStorage.setItem('errorRazonSocial', JSON.stringify(dataAfiliacionError));
        }

        // Folio

        let countFolio:any[] = []
        let band3:boolean = true;
        const matriz3:any = {}
        const matriz4:any = {}
        let dataError3:any[] = []
        let errorFolio:any[] = []
        const objeto={}
        let valores3 = ["NOMBREDELCOMERCIO","MERCHANTID","CALLEYNUMERODELCOMERCIO","NOMBREDELCOMERCIO","INDUSTRIA","CIUDADDELCOMERCIO","MAXTURBO","BNTEVEND","RB","MONEDA","CAJA","TIDAMEX","TERMINALID","REFERENCIA1","REFERENCIA2","REFERENCIA3","BW","BL","INDEX"];
        valores3.map((item:any,index:number) =>{
          const constante = constantes[item];
          const parametro = vall[constante.numero-2];
          const regex = new RegExp(constante.exReg);
          console.log(parametro)
          if(parametro !== undefined && parametro !== null){ 
            if(regex.test(parametro)){
              // PARA DETECTAR FOLIOS MULTIMERCHAN

              // FIN PARA DETECTAR FOLIOS MULTIMERCHAN
              let valor:any = "";
              let dataPlantillas:string = localStorage.getItem('plantillas') ?? "";
              let plantillas:any = JSON.parse(dataPlantillas);
              if(plantillas[constante.name] !== undefined){
                if (plantillas[constante.name].type==="number") {
                  // Es un número
                  valor = Number(parametro);
                } else if (plantillas[constante.name].type==="boolean") {
                  // Es un booleano
                  if(parametro === "TRUE") {
                    valor = true;
                  }else{
                    valor = false;
                  }
                  console.log(valor)
                } else {
                  // Es un string
                  valor = parametro;
                }
                console.log(valor)
                if(valor===null){
                  //matriz3[constante.name]="";
                  band3 = false;
                  dataError.push({
                    index:indexx,
                    search:false,
                    parametro:item,
                  })
                }else{
                  if(item==="INDUSTRIA"){
                    const opciones:any = {
                      R: "Retail",
                      T: "Restaurant",
                      H: "Hotel",
                      C: "CarRental",
                      G: "Gasolina",
                      S: "Sucursal",
                    };
                    const cadenaMayuscula = valor.toUpperCase();
                    matriz3[constante.name]=opciones[cadenaMayuscula[0]];
                  }else if(item==="MERCHANTID"){
                    matriz3["merchandId"]=`${valor}`;
                  }else if(item === "CIUDADDELCOMERCIO"){
                    matriz3[constante.name]=valor+` ${vall[5]}`;
                  }else if(item === "INDEX"){
                    matriz3[constante.name]=0;
                  }else{
                    matriz3[constante.name]=valor;
                  }
                }

              }else{
                if(item==="MERCHANTID"){
                  matriz3["merchandId"]=`${parametro}`;
                }else if (item === "INDEX"){
                  valor = Number(parametro);
                  valor = valor-1;
                  matriz3[constante.name]=0;
                }else{
                  matriz3[constante.name]=parametro;
                }

              }
            }else{
              band3 = false;
              dataError3.push({
                index:indexx,
                search:false,
                parametro:item,
              })
            }
          }else{
            band3 = false;
            dataError3.push({
              index:indexx,
              search:true,
              parametro:item,
            })
          }
        })
        let valores4 = ["PD","QPS","LIMITEQPS","LEYENDAQPS"];
        valores4.map((item:any,index:number) =>{
          const constante = constantes[item];
          const parametro = vall[constante.numero-2];
          const regex = new RegExp(constante.exReg);
          if(parametro !== undefined && parametro !== null){ 
            if(regex.test(parametro)){
              let valor:any = "";
              let dataPlantillas:string = localStorage.getItem('plantillas') ?? "";
              let plantillas:any = JSON.parse(dataPlantillas);
              if(plantillas[constante.name] !== undefined){
                if (plantillas[constante.name].type==="number") {
                  // Es un número
                  valor = Number(parametro);
                } else if (plantillas[constante.name].type==="boolean") {
                  // Es un booleano
                  if(parametro === "TRUE") {
                    valor = true;
                  }else{
                    valor = false;
                  }
                  console.log(valor)
                } else {
                  // Es un string
                  valor = parametro;
                }
                console.log(valor)
                if(valor===null){
                  matriz4[constante.name]="";
                }else{
                  matriz4[constante.name]=valor;
                }

              }else{
                //matriz4[constante.name]=parametro;
                if(parametro===null){
                  matriz4[constante.name]="";
                }else{
                  matriz4[constante.name]=parametro;
                }
              }
            }else{
              band3 = false;
              dataError3.push({
                index:indexx,
                search:false,
                parametro:item,
              })
            } 
          }else{
            band3 = false;
            dataError3.push({
              index:indexx,
              search:true,
              parametro:item,
            })
          }
        })
        if(band3) {
          // MULTIMERCHAN
            const constante = constantes["TERMINALID"];
            let countMultimerchan:string = localStorage.getItem('countMultimerchan') ?? "";
            let batchFolio:string = localStorage.getItem('batchFolio') ?? "";
            let dataBatch:any[] = JSON.parse(batchFolio);
            let dataMultimerchan:any[] = JSON.parse(countMultimerchan);

              dataMultimerchan.push(vall[constante.numero-2])
              localStorage.setItem('countMultimerchan', JSON.stringify(dataMultimerchan));
              afiliationId=vall[constante.numero-2];

              // SAVE DATA FOLIOOO
              const comercioF = {
                "templateId":"b3618d37-b3e6-40e2-a02f-f2d19582b425",
                "parametersArray": [matriz3]
              }
              const terminalF = {
                "templateId":"c829e7b7-b956-4695-8222-c6221cf248c0",
                "parameters": matriz4
              }
              let configurations:any = []
      
              configurations.push(comercioF)
              configurations.push(terminalF)
              let batch:string = localStorage.getItem('batchFolio') ?? "";
              dataBatch.push({
                affiliationId:`${vall[17]}`,
                description:`${vall[13]}`,
                folioId: `${vall[12]}`,
                configurations
              })
              console.log(dataBatch)
              localStorage.setItem('batchFolio', JSON.stringify(dataBatch));
    
              let errorAfiliacion:string = localStorage.getItem('countFolio') ?? "";
              let dataAfiliacion:any[] = JSON.parse(errorAfiliacion);
              dataAfiliacion.push(indexx)
              localStorage.setItem('countFolio', JSON.stringify(dataAfiliacion));
            
            console.log("MULTIMERCHAN",dataMultimerchan)
          
          // FIN MULTIMERCHAN

        }else{
          localStorage.removeItem('batchFolio');
          localStorage.setItem('batchFolio', JSON.stringify([]));
          let errorAfiliacion:string = localStorage.getItem('errorFolio') ?? "";
          let dataAfiliacionError:any[] = JSON.parse(errorAfiliacion);
          dataAfiliacionError.push(dataError3)
          localStorage.setItem('errorFolio', JSON.stringify(dataAfiliacionError));
        }

      }
    });   
  }

  async cvsForm() {
    localStorage.removeItem('batchFolio');
    localStorage.setItem('batchFolio', JSON.stringify([]));
    localStorage.removeItem('batchAfiliacion');
    localStorage.setItem('batchAfiliacion', JSON.stringify([]));
    localStorage.removeItem('batchRazonSocial');
    localStorage.setItem('batchRazonSocial', JSON.stringify([]));

    let final_vals: any[] = [];
    let indices:any = [];
    let templatesVerificar = this.templatesVerificar;
    let templates = this.templates;
    let titulosCSV:any = [];
    let afiliationId = ""
    let countAfiliacionn = this.countAfiliacionn
    let errorAfiliacionn = this.errorAfiliacionn
    //let validarTipoJson = this.validarTipoJson;
    let verificacion:any = "";
    let recorrerMatriz = this.recorrerMatriz;
    let data: RequestBatchFolioCreate[] = [];
    let csvReader = new FileReader(); // generate a filereader from the JS API
    //const alertCampo = this.alertCampo;
    const area = this.displayArea;
    let values:any[] = []
    const disabledButton = this.disabledButton;
    console.log(this.botonCsv)
    const input = this.seleccionarArchivo.files[0]; // grab the first (only) file from the input
    console.log("comienza",input)
      readXlsxFile(input).then(async(rows:any) => {
        await recorrerMatriz(rows)
        // `rows` is an array of rows
        // each row being an array of cells.
      })

    this.loader = true;
    setTimeout(() => { 
      let countAfiliacion:string = localStorage.getItem('countAfiliacion') ?? "";
      console.log(countAfiliacion)
      let dataAfiliacion:any = JSON.parse(countAfiliacion);
      this.countAfiliacionn = dataAfiliacion;

      let errorAfiliacion:string = localStorage.getItem('errorAfiliacion') ?? "";
      let dataAfiliacionError:any = JSON.parse(errorAfiliacion);
      this.errorAfiliacionn = dataAfiliacionError;

      let countRazonSocial:string = localStorage.getItem('countRazonSocial') ?? "";
      let dataRazonSocial:any = JSON.parse(countRazonSocial);
      this.countRazonSociall = dataRazonSocial;

      let errorRazonSocial:string = localStorage.getItem('errorRazonSocial') ?? "";
      let dataRazonSocialError:any = JSON.parse(errorRazonSocial);
      this.errorRazonSociall = dataRazonSocialError;

      let countFolio:string = localStorage.getItem('countFolio') ?? "";
      let dataFolio:any = JSON.parse(countFolio);
      //this.countFolio = dataFolio;

      let countMultimerchan:string = localStorage.getItem('countMultimerchan') ?? "";
      let dataMultimerchan:any = JSON.parse(countMultimerchan);
      //this.countMultimerchan = dataMultimerchan;
      this.countFolio = [];
      let batchFolio:string = localStorage.getItem('batchFolio') ?? "";
      let dataBatchFolio:any = JSON.parse(batchFolio);
      dataBatchFolio.map((item:any,index:number)=>{
        if(item.configurations[0].parametersArray.length>1){
          this.countMultimerchan.push(item)
        }else{
          this.countFolio.push(item)
        }
      })

      let errorFolio:string = localStorage.getItem('errorFolio') ?? "";
      let dataFolioError:any = JSON.parse(errorFolio);
      this.errorFolio = dataFolioError;

      this.bandTable = 1;
      this.loader = false;
      console.log("termina")
    }, 1000);

    csvReader.readAsText(input);
  }

  render() {
    return html`
      <ui5-dialog
        id="dialog-pre-configuracion-edicion"
        header-text=${'Edicion Banorte'}
        >
        ${
          this.bandCarga==0 ? html`
          <div
          class="px-4 flex flex-col items-center"
        >
          <div class="mx-2 w-full flex flex-col items-center">
          <div class=" pt-3 flex items-center mb-5 ">
                <p class="text-base	text-blue-500	ml-3 ">Plantilas Disponibles</p>
          </div>
          <div class=" pt-3 flex items-center mb-5 ">
          </div>
          <ul class="list-disc pl-2"> 
                ${this.preConfig.map(
                  (folio:any, index:number) => html`
                    <li
                      class="flex items-center gap-2 ml-2"
                    >
                    <p class="text-slate-500 hover:text-blue-600 text-lg "></p>
                      <p class="text-slate-500 hover:text-blue-600 text-lg ">${folio.name}</p>
                      <select name="cars" class="w-[210px] mx-1 mb-3" id="appSelect"
                      @change=${(ev: any) => {
                        console.log(ev.target.value)
                        this.getSavedConfiguration(ev.target.value)
                        console.log(this.count)
                      }}>
                        <option disabled selected></option>
                        <option value="${folio.id}">${folio.name}</option>
                        
                      </select> 
                    </li>
                  `
                )}
              </ul>
          </div>
  
          <div slot="footer" class="dialog-footer">
            <button
              class="text-red-600 p-4 rounded-md uppercase"
              id="closeDialogButton"
              @click=${() => {
                this.remove();
                this.close();
              }}
            >
              Cerrar
            </button>
            <button
              class="text-blue-600 p-4 rounded-md uppercase"
              id="closeDialogButton"
              @click=${(e: Event) => {
                  this.bandCarga = 1;
              }}
              .disabled=${this.habilitar}
            >
              Guardar
            </button>
          </div>
        </div>
          `
          : this.bandCarga==1 ?
          html`
          <div class="px-4 container mx-auto" style="width: 500px;">
          <div class="bg-white flex justify-center py-4">
            <button
              @click=${(e: Event) => {
                  this.remove();
                  this.close()
              }}
            class="bg-red-500 text-white px-4 py-2 rounded-md mr-2">Cancelar</button>
            <div>
            <input
            id="seleccionar-archivo-banorte"
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            placeholder="label"
            type="file"
            @change=${async(ev: InputEvent) => {
              this.remove();
              await this.cvsForm();
            }}
            hidden
          />
            <button
            class="bg-blue-500 text-white px-4 py-2 rounded-md mr-2"
            @click=${() => {
              this.botonCsv.click();
              this.band = 1;
            }}
            }}
          >
            Seleccionar
          </button>
  
            </div>
  
          </div>
      
          ${ this.bandTable ? html`
          <div class="mt-4">
          ${this.loader !== true
            ? html`
          <table class="border-none table-auto w-full">
          <thead>
            <tr class="border-none text-gray-700 font-bold">
              <th class="border-none px-4 py-2">Elemento</th>
              <th class="border-none px-4 py-2">Correctos</th>
              <th class="border-none px-4 py-2">Fallas</th>
            </tr>
          </thead>
          <tbody>
            <tr class="border-none text-gray-700">
              <td class="border-none px-4 py-2">Razones Sociales</td>
              <td class="border-none px-4 py-2">${this.countRazonSociall?.length}</td>
              <a href="#" class="underline text-blue-500 hover:text-blue-700">
              <td class="border-none px-4 py-2 cursor-pointer hover:text-blue-700 text-blue-500"
              @click=${() => {
                this.mostrarFalladosModal.open(this.errorRazonSociall);
              }}
              >${this.errorRazonSociall?.length}</td></a>
            </tr>
            <tr class="border-none text-gray-700">
              <td class="border-none px-4 py-2">Afiliaciones</td>
              <td class="border-none px-4 py-2">${this.countAfiliacionn?.length}</td>
              <td class="border-none px-4 py-2 cursor-pointer hover:text-blue-700 text-blue-500"
              @click=${() => {
                this.mostrarFalladosModal.open(this.errorAfiliacionn);
              }}
              >${this.errorAfiliacionn?.length}</td>
            </tr>
            <tr class="border-none text-gray-700">
              <td class="border-none px-4 py-2">Folios</td>
              <td class="border-none px-4 py-2">${this.countFolio?.length}</td>
              <a href="#" class="underline text-blue-500 hover:text-blue-700">
              <td class="border-none px-4 py-2 cursor-pointer hover:text-blue-700 text-blue-500"
              @click=${() => {
                this.mostrarFalladosModal.open(this.errorFolio);
              }}
              >${this.errorFolio?.length}</td></a>
            </tr>
          </tbody>
        </table>
        `
        : html`
            <mwc-circular-progress
              indeterminate
              density=${20}
            ></mwc-circular-progress>
          `}
            </div>
                  `
              : html`
              `
          }
  
          </div>
      
          <div class="bg-white flex justify-center py-4 mt-4">
            <button 
            @click=${(e: Event) => {
              //this.bandCarga = 2;
              this.enviarCarga()
            }}
            class="bg-blue-500 text-white px-4 py-2 rounded-md">Enviar Carga</button>
          </div>
        </div>
          `
          :
          html`
          <div class="px-4 container mx-auto" style="width: 500px;">
          <div class="bg-white flex justify-center py-4">
            <button
            @click=${(e: Event) => {
              this.remove();
              this.bandCarga = 1;
              }}
            class="bg-blue-500 text-white px-4 py-2 rounded-md">Volver</button>
          </div>
      
          <div class="mt-4">
          <table class="border-none table-auto w-full">
          <thead>
            <tr class="border-none text-gray-700 font-bold">
              <th class="border-none px-4 py-2">Elemento</th>
              <th class="border-none px-4 py-2">Resultados</th>
            </tr>
          </thead>
        
          <tbody>
            <tr class="border-none text-gray-700">
              <td class="border-none px-4 py-2 text-center">Razones Sociales</td>
              <a href="#" class="underline text-blue-500 hover:text-blue-700">
              <td class="border-none px-4 py-2 text-center cursor-pointer hover:text-blue-700 text-blue-500"
              @click=${() => {
                this.mostrarRegistradosModal.open(this.countSuccessRazonSocial,this.dataRazonSocial,this.countSuccessAffiliation,this.dataAfiliacion,this.countSuccessFolio,this.dataFolio,[]);
              }}
              >${this.countSuccessRazonSocial.length}</td></a>
              <td class="border-none px-4 py-2"></td>
            </tr>
            <tr class="border-none text-gray-700">
              <td class="border-none px-4 py-2 text-center">Afiliaciones</td>
              <a href="#" class="underline text-blue-500 hover:text-blue-700">
              <td class="border-none px-4 py-2 text-center cursor-pointer hover:text-blue-700 text-blue-500"
              @click=${() => {
                this.mostrarRegistradosModal.open(this.countSuccessRazonSocial,this.dataRazonSocial,this.countSuccessAffiliation,this.dataAfiliacion,this.countSuccessFolio,this.dataFolio,[]);
              }}
              >${this.countSuccessAffiliation.length}</td></a>
              <td class="border-none px-4 py-2"></td>
            </tr>
            <tr class="border-none text-gray-700">
              <td class="border-none px-4 py-2 text-center">Folios</td>
              <a href="#" class="underline text-blue-500 hover:text-blue-700">
              <td class="border-none px-4 py-2 text-center cursor-pointer hover:text-blue-700 text-blue-500"
              @click=${() => {
                this.mostrarRegistradosModal.open(this.countSuccessRazonSocial,this.dataRazonSocial,this.countSuccessAffiliation,this.dataAfiliacion,this.countSuccessFolio,this.dataFolio,[]);
              }}
              >${this.countSuccessFolio.length}</td></a>
              <td class="border-none px-4 py-2"></td>
            </tr>
          </tbody>
        </table>
        
            </div>
          </div>
      
        </div>
  
        <div slot="footer" class="dialog-footer">
        <button
          class="text-red-600 p-4 rounded-md uppercase"
          id="closeDialogButton"
          @click=${() => {
            this.close();
          }}
        >
          Cerrar
        </button>
        </div>
          `
        }
  
        </ui5-dialog>
    `;
  }
}