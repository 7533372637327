/* eslint-disable prettier/prettier */

import { LitElement, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import '@ui5/webcomponents/dist/Dialog.js';
import '@vaadin/vaadin-select';
import {
  CommunicationTerminalApi,
  CommunicationTerminalApiRequestCommandRequest,
  RequestCommandTypeCommandEnum,
  Terminal,
} from '../../../api';
import { errorNotification } from '../../services/notifications';
import { apisConfigurator } from '../../utils/axios';
import writeXlsxFile from 'write-excel-file'

@customElement('mostrar-registrados-modal')
export class MostrarRegistradosModal extends LitElement {
  createRenderRoot() {
    return this; // turn off shadow dom to access external styles
  }

  private communicationApi: CommunicationTerminalApi;

  constructor() {
    super();

    this.communicationApi = new CommunicationTerminalApi(
      ...apisConfigurator('Comunicación')
    );
  }

  @property({ type: Object })
  terminal?: Terminal;

  @state()
  private titulo = '';

  @state()
  private productos:any[] = [];

  @state()
  private mensaje = '';

  @state()
  private operacion = '';

  @state()
  private dataRegistroRazonSocial:any = '';

  @state()
  private registradosRazonSocial:any[] = [];

  @state()
  private dataRegistroAfiliacion:any = '';

  @state()
  private registradosAfiliacion:any[] = [];

  @state()
  private dataRegistroFolio:any = '';

  @state()
  private registradosFolio:any[] = [];

  @state()
  private listaFolio:any[] = [];

  @state()
  private band:boolean = false;

  private get dialog() {
    return document.getElementById('registros-carga')! as any;
  }

  protected firstUpdated(): void {}

  async getOrganizaciones() {}

  async open(registradosRazonSocial:any,dataRegistroRazonSocial:any,registradosAfiliacion:any,dataRegistroAfiliacion:any,registradosFolio:any,dataRegistroFolio:any,listaFolio:any) {
    this.registradosRazonSocial = registradosRazonSocial;
    this.dataRegistroRazonSocial = dataRegistroRazonSocial;
    this.listaFolio=listaFolio;

    this.registradosAfiliacion = registradosAfiliacion;
    this.dataRegistroAfiliacion = dataRegistroAfiliacion;

    this.registradosFolio = registradosFolio;
    this.dataRegistroFolio = dataRegistroFolio;

    this.dialog.open = true;
    this.band=true;

    let Afiliacion;
    let Rs;

  }

  close() { 
    this.dialog.open = false;
    this.terminal = undefined;
  }

  async exportarXLS(){
    let cabeceras = ["Folio","Afiliacion","Razón Social","Status"];
    await cabeceras.map(async(item:any,index:number)=>{
      this.productos.push({
        value:item
      })
    })
    const data1:any = [
      this.productos,
    ];
    let dataRegistroFolio = this.dataRegistroFolio;
    let item:any = [];
    let i = 0; 
    let maximoIndice = Math.max(this.registradosFolio.length, this.registradosAfiliacion.length, this.registradosRazonSocial.length);
    do {
      item = []
      if (i < this.registradosFolio.length) {

        item[0] = {value:this.registradosFolio[i].id};
        
        this.registradosAfiliacion.map((itemn:any,index:number) => {
          if(itemn.id.includes(this.dataRegistroFolio[i].affiliationId))
            item[1] = {value:itemn.id};
        })
      }
      if (i < this.registradosAfiliacion.length) {
        this.registradosRazonSocial.map((itemn:any,index:number) => {
          if(itemn.id===this.dataRegistroAfiliacion[i].businessId)
            item[2] = {value:itemn.id};
        })
      }
      console.log(this.registradosFolio[i])
      console.log(this.registradosFolio[i].id.includes("."))
      if (i < this.registradosRazonSocial.length) {
        if(this.registradosFolio[i].id.includes(".") || this.registradosAfiliacion[i].status !== "success" || (this.registradosRazonSocial[i].status !== "success"))
          item[3] = {value:`Folio:${this.registradosFolio[i].id ? "success":  this.registradosFolio[i].status} Afiliacion:${this.registradosAfiliacion[i].status}, Razon Social:${this.registradosRazonSocial[i].status}`};
        else 
          item[3] = {value:"success"};
      }
      i++;
      data1.push(item);
    } while (i < maximoIndice);
    console.log(data1);
    await writeXlsxFile(data1, {
      fileName: 'Resultados_Carga_Masiva.xlsx'
    })
  }

  exportarCSV(){
    //const productos = [ ["C","afiliationId", "description", "cantidad" ], [ "CP", "Banorte-autorizador", "Amex", "port", "tls","url" ], [ "CP", "Banorte-comercio", "business ","name", "comboRef","" ], [ "CP","Banorte-terminal-F", "precio", 49.99 ] ]
    //const productValuesArrays = productos.map(producto =>Object.values(producto));
    //productValuesArrays.unshift(Object.keys(productos));
    const csvContent = "data:text/csv;charset=utf-8," 
    + this.productos.map((row:any) => row.join(";")).join("\n");

    const encodedUri = encodeURI(csvContent);
    
const link = document.createElement("a");
link.setAttribute("href", encodedUri);
link.setAttribute("download", "resultados.csv");
document.body.appendChild(link); 

link.click(); // This will download the CSV file named "products.csv".

  }

  private async handleSubmit(e: Event) {
    e.preventDefault();
 
    //console.log('terminal', this.terminal);
    if (!this.terminal) return;
    const requestCommand: CommunicationTerminalApiRequestCommandRequest = {
      brand: this.terminal.brand,
      serial: this.terminal.serial,
      requestCommand: {
        commandId: `displayMessage-${this.terminal.serial}-${Date.now()}`,
        typeCommand: RequestCommandTypeCommandEnum.DisplayMessage,
        parameters: [this.titulo, this.mensaje ?? this.titulo],
      },
    };

    try {
      this.close();
      const res = await this.communicationApi.requestCommand(requestCommand);
      //console.log('envio mensaje res', res);
    } catch (err: any) {
      console.log('envio mensaje err', err);
    }
  }

  private renderCommandRazonSocial(item: any, index:number) {
    let dataRegistro = this.dataRegistroRazonSocial;
    let Rs = dataRegistro.find((producto:any) => producto.businessId === item.id);
    
    return html`<div class="flex flex-row justify-between border-y p-2">
    <div class="flex flex-col">
      <span class="text-gray-600 text-sm"
      ><b>Razon Social:</b> ${item.id}</span>

      <span class="text-gray-600 text-sm"
        ><b>status:</b> ${item.status}</span>
    </div>
    <br>
    <div class="flex flex-col"></div>
    </div>`;
  }

  private renderCommandFolio(item: any, index:number) {
    let dataRegistro = this.dataRegistroFolio;
console.log(item)
    return html`<div class="flex flex-row justify-between border-y p-2">
    <div class="flex flex-col">
      <span class="text-gray-600 text-sm"
      ><b>Folio:</b> ${item.id}</span>

      <span class="text-gray-600 text-sm"
      ><b>Descripcion:</b> ${dataRegistro[index].description}</span> 

      <span class="text-gray-600 text-sm"
        ><b>Status:</b> ${item.hasOwnProperty('status') ? item.status : "success"}</span>
    </div>
    <br>`;
  }


  private renderCommandAfiliacion(item: any, index:number) {
    let dataRegistro = this.dataRegistroAfiliacion;
    let Afiliacion = dataRegistro.find((producto:any) => producto.affiliationId === item.id);

    return html`<div class="flex flex-row justify-between border-y p-2">

    <div class="flex flex-col">
      <span class="text-gray-600 text-sm"
      ><b>Afiliacion:</b> ${item.id}</span>

      <span class="text-gray-600 text-sm"
      ><b>Nombre Comercio:</b> ${Afiliacion.businessId}</span>

      <span class="text-gray-600 text-sm"
        ><b>status:</b> ${item.status}</span>
    </div>
    <br>
    <div class="flex flex-col"></div>
    </div>`;
  }

  

  protected render() {
    return html` <ui5-dialog
      id="registros-carga"
      header-text="Resultados"
      class="w-3/12"
    >
      <form 
        class="px-4 flex flex-col items-center"
        @submit=${this.handleSubmit}
      >
        <div class="flex flex-col w-full overflow-y-auto max-h-80 mb-2">
          ${this.registradosRazonSocial?.map((item:any,index:number) => this.renderCommandRazonSocial(item,index))}
          ${this.registradosFolio?.map((item:any,index:number) => this.renderCommandFolio(item,index))}
          ${this.registradosAfiliacion?.map((item:any,index:number) => this.renderCommandAfiliacion(item,index))}
        </div>
        <div slot="footer" class="dialog-footer">
        <button
          class="text-red-600 p-4 rounded-md uppercase"
          id="closeDialogButton"
          @click=${() => {
            this.close();
          }}
        >
          Cerrar
        </button>
        <button
        class="text-green-600 p-4 rounded-md uppercase"
        id="closeDialogButton"
        @click=${() => {
          this.exportarXLS()
        }}
      >
        Exportar
      </button>

        </div>
      </form>
    </ui5-dialog>`;
  }
}
