/* eslint-disable prettier/prettier */

import { LitElement, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import '@ui5/webcomponents/dist/Dialog.js';
import '@vaadin/vaadin-select';
import {
  CommunicationTerminalApi,
  CommunicationTerminalApiRequestCommandRequest,
  RequestCommandTypeCommandEnum,
  Terminal,
} from '../../../api';
import { errorNotification } from '../../services/notifications';
import { apisConfigurator } from '../../utils/axios';
import writeXlsxFile from 'write-excel-file'

@customElement('mostrar-fallados-modal')
export class MostrarFalladosModal extends LitElement {
  createRenderRoot() {
    return this; // turn off shadow dom to access external styles
  }

  private communicationApi: CommunicationTerminalApi;

  constructor() {
    super();

    this.communicationApi = new CommunicationTerminalApi(
      ...apisConfigurator('Comunicación')
    );
  }

  @property({ type: Object })
  terminal?: Terminal;

  @state()
  private titulo = '';

  @state()
  private productos:any[] = [];


  @state()
  private errores:any[] = [];

  @state()
  private mensaje = '';

  @state()
  private band:boolean = false;

  private get dialog() {
    return document.getElementById('errores-carga')! as any;
  }

  protected firstUpdated(): void {}

  async getOrganizaciones() {}

  async open(errores:any) {
    this.errores = errores;
    console.log(errores) 
    this.dialog.open = true; 
    this.band=true;
  }

  close() { 
    this.dialog.open = false;
    this.terminal = undefined;
  }
  async exportarXLS(){
    let cabeceras = ["Index","affiliationId","Error"];
    await cabeceras.map(async(item:any,index:number)=>{
      this.productos.push({
        value:item
      })
    })
    const data1:any = [
      this.productos,
    ];
    console.log(data1);
    this.errores.map((item:any,index:number)=>{
      let itemFolio:any = [];
      //this.productos.push([`${item[0].index}`,`parametro ${item[0].parametro} no valido`])
      itemFolio[0] = {value:item[0].index};
      itemFolio[2] = {value:`parametro ${item[0].parametro} no valido`};
      itemFolio[1] = {value:item[0].affiliationId};
      data1.push(itemFolio)
    });
    await writeXlsxFile(data1, {
      fileName: 'Fallas_Carga_Masiva.xlsx'
    })
  }

  exportarCSV(){
    //const productos = [ ["C","afiliationId", "description", "cantidad" ], [ "CP", "Banorte-autorizador", "Amex", "port", "tls","url" ], [ "CP", "Banorte-comercio", "business ","name", "comboRef","" ], [ "CP","Banorte-terminal-F", "precio", 49.99 ] ]
    //const productValuesArrays = productos.map(producto =>Object.values(producto));
    //productValuesArrays.unshift(Object.keys(productos));
    const csvContent = "data:text/csv;charset=utf-8," 
    + this.productos.map((row:any) => row.join(";")).join("\n");

    const encodedUri = encodeURI(csvContent);
    
const link = document.createElement("a");
link.setAttribute("href", encodedUri);
link.setAttribute("download", "Errores.csv");
document.body.appendChild(link); 

link.click(); // This will download the CSV file named "products.csv".
  }

  private async handleSubmit(e: Event) {
    e.preventDefault();
 
    //console.log('terminal', this.terminal);
    if (!this.terminal) return;
    const requestCommand: CommunicationTerminalApiRequestCommandRequest = {
      brand: this.terminal.brand,
      serial: this.terminal.serial,
      requestCommand: {
        commandId: `displayMessage-${this.terminal.serial}-${Date.now()}`,
        typeCommand: RequestCommandTypeCommandEnum.DisplayMessage,
        parameters: [this.titulo, this.mensaje ?? this.titulo],
      },
    };

    try {
      this.close();
      const res = await this.communicationApi.requestCommand(requestCommand);
      //console.log('envio mensaje res', res);
    } catch (err: any) {
      console.log('envio mensaje err', err);
    }
  }

  private renderCommand(item: any) {
    return html`<div class="flex flex-row justify-between border-y p-2">
      <div class="flex flex-col">
      ${
        item.search ? html`
          <span class="text-gray-600 text-sm"
          ><b>Index:</b> ${item.index}</span
          >
          <span class="text-gray-600 text-sm"><b>Informacion: parametro </b>${item.parametro} no encontrado</span>
          <span class="text-gray-600 text-sm"><b>Afiliacion: </b>${item.affiliationId}</span>
        `
        : 
        html`
          <span class="text-gray-600 text-sm"
          ><b>Index:</b> ${item.index}</span
          >
          <span class="text-gray-600 text-sm"
            ><b>Informacion: parametro </b>${item.parametro} no valido</span
          >
            <span class="text-gray-600 text-sm"><b>Afiliacion: </b>${item.affiliationId}</span>
        `
      }
      </div>
      <div class="flex flex-col"></div>
    </div>`;
  }

  protected render() {
    return html` <ui5-dialog
      id="errores-carga"
      header-text="Indices de Errores"
      class="w-3/12"
    >
      <form 
        class="px-4 flex flex-col items-center"
        @submit=${this.handleSubmit}
      >
        <div class="flex flex-col w-full overflow-y-auto max-h-80 mb-2">
            ${this.errores?.map(item => this.renderCommand(item[0]))}
        </div>

        <div slot="footer" class="dialog-footer">
          <button
            class="text-red-600 p-4 rounded-md uppercase"
            id="closeDialogButton"
            @click=${() => {
              this.close();
            }}
          >
            Cerrar
          </button>
          <button
          class="text-green-600 p-4 rounded-md uppercase"
          id="closeDialogButton"
          @click=${() => {
            this.exportarXLS()
          }}
        >
          Exportar
        </button>
        </div>
      </form>
    </ui5-dialog>`;
  }
}
